<template>
	<div class="com-plant-status-add">
		<a-button :type="btnType" :size="btnSize" @click="showAdd">
			{{info ? '编辑':'添加状态'}}
		</a-button>
		<a-modal v-model:visible="show" title="添加状态" @ok="addPlantStatus" @cancel="show = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="成熟度">
					<a-select v-model:value="form.maturity">
						<a-select-option value="幼苗期">幼苗期</a-select-option>
						<a-select-option value="生长期">生长期</a-select-option>
						<a-select-option value="成熟期">成熟期</a-select-option>
						<a-select-option value="枯萎期">枯萎期</a-select-option>
					</a-select>
				</a-form-item>
				<template v-if="form.server_content">
					<a-form-item :label="item.name" v-for="(item,index) in form.server_content" :key="index">
						<a-radio-group v-model:value="item.is_need">
							<a-radio :value='1'>需要</a-radio>
							<a-radio :value="0">不需要</a-radio>
						</a-radio-group>
					</a-form-item>
				</template>
				<a-form-item label="轮播图">
					<kd-img-select :mutil="true" :src="form.src" @success="e=>form.src=e"></kd-img-select>
				</a-form-item>
				<a-form-item label="预估产量">
					<a-input placeholder="预估产量" v-model:value="form.yield" addon-after="kg"></a-input>
				</a-form-item>
				<a-form-item label="文字说明">
					<a-textarea v-model:value="form.description" placeholder="请输入内容" :rows="4" />
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>
<script>
import landModel from '@/api/land.js'
import { reactive,toRefs } from 'vue'
import tool from '@/util/tool.js'
export default{
	props:{
		btnType:{
			type:String,
			default:'primary'
		},
		btnSize:{
			type:String,
			default:'default'
		},
		ids:{
			type:[Object,Array,Number]
		},
		landId:{
			type:[String,Number],
			default:false,
		},
		info:{
			type:Object,
			default:null
		}
	},
	setup(props,context){
		const _d = reactive({
			show:false,
			form:{
				maturity:"",
				yield:"",
				src:[],
				description:"",
				server_content:[],
				plant_id:0
			}
		})

		function showAdd(){
			let { landId,info } =props
			_d.show = true
			let form = {
				maturity:info ? info.maturity : "",
				yield:info ? info.yield : "",
				src:info ? info.src_show : [],
				description:info ? info.description : "",
				server_content:info ? info.server_content : [],
				plant_id:info ? info.plant_id : 0,
			}
			landModel.getLandServerById(landId,res=>{
				res.forEach(item=>{
					form.server_content.push({
						server_id:item.id,
						name:item.title,
						is_need:0,		//0 不需要要 1需要
					})
				})
				_d.form = form
			})
		}
		
		function addPlantStatus(){
			let { ids,info } = props ,form = _d.form
			if( typeof ids == 'object' ){
				if(ids.length <=0 ){
					tool.message("暂时没有种植信息需要添加状态",'warning')
					return
				}
				let _ids = []
				if( typeof ids[0] == 'object' ){
					ids.forEach(item=>{
						_ids.push(item.id)
					})
					form.plant_id = _ids
				}else{
					form.plant_id = ids
				}
			}else{
				form.plant_id = ids
			}
			if( info ) form.id = info.id
			landModel.addOrEditPlantStatus(form,()=>{
				_d.show = false
				context.emit("success")
			})
		}

		return{
			...toRefs(_d),
			showAdd,
			addPlantStatus
		}
	}
}
</script>

<style lang="scss">
</style>
